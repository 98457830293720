<template class="">
  <SteamBanner></SteamBanner>
  <Navbar></Navbar>
  <router-view/>

  <Footer></Footer>
</template>


<script>
import Navbar from '@/components/Navbar.vue'
import Footer from './components/Footer.vue';
import SteamBanner from './components/SteamBanner.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Navbar,
    Footer,
    SteamBanner
  },
  async mounted() {
    await this.$store.dispatch('getLoggedInUser');
  },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    async $route(to, from) {
      await this.$store.dispatch('getLoggedInUser');// Update their balance and such on page change
    }
  }
}
</script>

<style lang="scss">

</style>
