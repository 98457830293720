import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import api from '@/api';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  plugins: [ vuexLocal.plugin ],
  state: {
    user: null,
    loggedInUserId: null,
  },
  getters: {
    user: state => state.user,
  },
  mutations: {
    setUser(state, user) { state.user = user },
    setLoggedInUserId(state, userId) { console.log('setting state id + ' + userId); state.loggedInUserId = userId },  
  },
  actions: {
    async getLoggedInUser({ commit, state }) { 
      if (!state.loggedInUserId) {
        commit('setUser', null);
        console.log(`Store attempted to get logged in user, but no user is logged in.`)
        return null;
      }

      let { data: user } = await api.get('/user', {
        params: {
          id: state.loggedInUserId
        }
      });

      commit('setUser', user);

      return user;
    },
    async setLoggedInUserId({ commit }, userId) {
      console.log(`userId: ${userId}`)
      commit('setLoggedInUserId', userId);
    },
    async login() {
      window.location = process.env.VUE_APP_API + '/auth/steam?origin=' + window.location.origin;
    },
    async logout({ commit }) { 
      commit('setUser', null);
    }
  },
  modules: {
  }
})
