<template>
  <div class="container">
  <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top">
    <div class="col mb-3">
      <a href="/" class="d-flex align-items-center mb-3 link-dark text-decoration-none">
        <div class="fw-bold h3 my-auto">SteamTrading<span class="text-primary">.Cards</span></div>
      </a>
      <p class="text-muted">© 2022</p>
      <!-- <p class="text-muted">Powered by Steam</p> -->
      <!-- <p class="text-muted">This site is not affiliated with Valve Corp.</p> -->
    </div>

    <div class="col mb-3 align-items-center d-flex">
    </div>
    
    <!-- <div class="col mb-3"></div> -->

    <div class="col mb-3">
      <h5>General</h5>
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><router-link to="/" class="nav-link p-0 text-muted">Home</router-link></li>
        <li class="nav-item mb-2"><router-link to="Level" class="nav-link p-0 text-muted">Level Up</router-link></li>
        <li class="nav-item mb-2"><router-link to="Trade" class="nav-link p-0 text-muted">Trade</router-link></li>
        <!-- <li class="nav-item mb-2"><router-link to="Sell" class="nav-link p-0 text-muted">Sell</router-link></li> -->
        <li class="nav-item mb-2"><router-link to="Orders" class="nav-link p-0 text-muted">Orders</router-link></li>
      </ul>
    </div>

    <div class="col mb-3">
      <h5>Support</h5>
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><router-link to="FAQ" class="nav-link p-0 text-muted">FAQ</router-link></li>
        <li class="nav-item mb-2"><router-link to="Contact" class="nav-link p-0 text-muted">Contact Us</router-link></li>
        <li class="nav-item mb-2"><a href="/tos.pdf" class="nav-link p-0 text-muted">Terms & Conditions</a></li>
      </ul>
    </div>

    <!-- <div class="col mb-3">
      <h5>Section</h5>
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><router-link to="Home" class="nav-link p-0 text-muted">Home</router-link></li>
        <li class="nav-item mb-2"><router-link to="Features" class="nav-link p-0 text-muted">Features</router-link></li>
        <li class="nav-item mb-2"><router-link to="Pricing" class="nav-link p-0 text-muted">Pricing</router-link></li>
        <li class="nav-item mb-2"><router-link to="FAQs" class="nav-link p-0 text-muted">FAQs</router-link></li>
        <li class="nav-item mb-2"><router-link to="About" class="nav-link p-0 text-muted">About</router-link></li>
      </ul>
    </div> -->

    <div class="col mb-3">
      <h5>Developers</h5>
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><router-link :to="{ name: 'APIDocs', hash: '#pricing' }" class="nav-link p-0 text-muted">Pricing API</router-link></li>
        <li class="nav-item mb-2"><router-link :to="{ name: 'APIDocs', hash: '#asset' }" class="nav-link p-0 text-muted">Asset API</router-link></li>
        <li class="nav-item mb-2"><router-link :to="{ name: 'APIDocs', hash: '#limits' }" class="nav-link p-0 text-muted">API Limits</router-link></li>
      </ul>
    </div>
  </footer>
</div>
</template>