<template>
  <div class="home">
    <div class="px-4 pt-5 my-5 text-center ">
    <div class="overflow-hidden" style="max-height: 30vh;">
      <div class="container px-5">
        <img src="@/assets/hero.png" class="img-fluid  rounded-3  mb-4" alt="Example image" width="700" height="500" loading="lazy">
      </div>
    </div>
    <h1 class="display-4 fw-bold">SteamTrading<span class="text-primary">.Cards</span></h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">Quickly and easily level up your Steam profile</p>
      <div class="d-grid gap-4 d-sm-flex justify-content-sm-center mb-5">
        <router-link custom to="/level" v-slot="{ navigate }">
          <button @click="navigate" type="button" class="btn btn-outline-primary btn-lg w-100 py-5">
            <div class="d-flex justify-content-center flex-column">
              <div class="">Level Up</div>
              <!-- <div class="text-black h6">Purchase sets</div> -->
            </div>
          </button>
        </router-link>
        
        <router-link custom to="/trade" v-slot="{ navigate }">
          <button @click="navigate" type="button" class="btn btn-outline-success btn-lg w-100 py-5">
            <div class="d-flex flex-column">
              <div class="">Trade</div>
              <!-- <div class="text-black h6">Advanced Users</div> -->
            </div>
          </button>
        </router-link>
        <!-- <button type="button" class="btn btn-outline-secondary btn-lg me-sm-3 w-100 py-5">Sell</button> -->
      </div>
    </div>
  </div>
    
  </div>
</template>

<script>
export default {
  components: {
    
  }
}
</script>
