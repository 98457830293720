var axios   = require('axios');
var qs      = require('qs');

var axiosInstance = axios.create({ baseURL: process.env.VUE_APP_API });

const { ConcurrencyManager } = require("axios-concurrency");
const MAX_CONCURRENT_REQUESTS = 5;

const manager = ConcurrencyManager(axiosInstance, MAX_CONCURRENT_REQUESTS);
// Use manager.detech() to turn off the manager.

let auth = localStorage.getItem('stctoken');
if (auth) {
    axiosInstance.defaults.headers.common['Authorization'] = `${auth}`;
}

module.exports = axiosInstance;

module.exports.setToken = function(token) {
    localStorage.setItem('stctoken', token);
    axiosInstance.defaults.headers.common['Authorization'] = `${token}`;
}

module.exports.getToken = function() {
    return axiosInstance.defaults.headers.common['Authorization'];
}

module.exports.getAxiosInstance = function() {
    return axiosInstance;
}