<template>
<nav class="navbar navbar-expand-md border-bottom border-primary" data-bs-theme="dark">
  <div class="container-fluid">
    <router-link class="navbar-brand" to="/">SteamTrading<span class="text-primary">.Cards</span></router-link>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <router-link class="nav-link" to="/">Home</router-link>
        </li>
      </ul>

      <ul class="navbar-nav me-auto d-flex pe-5">
          <li class="nav-item my-auto ">
            <router-link class="nav-link" to="/level">Level Up</router-link>
<!-- <button class="btn btn btn-primary me-1" aria-current="page" href="#" style="width:100px">Level Up</button> -->
          </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/trade">Trade</router-link>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#">Sell</a>
        </li> -->
      </ul>
      
      <div v-if="!user" class="d-flex" style="cursor: pointer;">
        <!-- <img @click="login" src="@/assets/sits_small.webp" alt=""> -->
        <img @click="login" src="@/assets/sits_01.png" alt="">
      </div>
      <div v-else>
        <ul class="navbar-nav flex-row flex-wrap ms-md-auto">
          <li class="nav-item dropdown">
              
            <div class="nav-link pe-3" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              
              <div class="d-flex">
                <div class="my-auto me-3">
                  <img :src="user.steamData.avatarfull" class="rounded-rounded" style="border-radius: 10px; box-shadow: 0px 0px 4px 2px rgba(102,129,46,0.75);" width="40" height="40" alt="">
                </div>
                <div>
                  <div class="">
                    <span class="me-1 fw-bold" >{{ user.steamData.personaname }}</span>
                  </div>
                  <div v-if="!isNaN(user.balance)">
                    <span class="font-monospace " style="color: #57cbde">{{ user.balance.toFixed(2) }} <i class="bi-gem" /></span>
                  </div>
                  <div v-else>
                    <!-- <span class="font-monospace " style="color: #57cbde">No Balance</span> -->
                  </div>
                </div>
                
                <div class="my-auto ms-2">
                  <i class="bi-caret-down-fill" style="font-size: 1.5rem;"></i>

                </div>
              </div>
            </div>
            <ul class="dropdown-menu" style="top: 3.35rem;">
              <li><router-link class="dropdown-item" to="orders">Orders</router-link></li>
              <!-- <li><router-link class="dropdown-item" to="transactions">Transactions</router-link></li> -->
              <!-- <li><router-link class="dropdown-item" to="orders">Transactions</router-link></li> -->
              <!-- <li><a class="dropdown-item" href="#">Another action</a></li> -->
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" @click="logout">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
  },
  methods: {
    login() {
      // this.$router.push({ name: 'login' })
      window.location = process.env.VUE_APP_API + '/auth/steam?origin=' + window.location.origin;
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'home' });
    }
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>

<style scoped lang="scss">
// .navbar {
//   background-color: #000;
//   color: #fff;
// }

.dropdown-menu {
  border-radius: 0;
  border: none;
}
</style>
