import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  // By using import() we lazy load the component making our initial download smaller.
  { path: '/login', name: 'login',  component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue') },
  { path: '/level', name: 'levelup',  component: () => import(/* webpackChunkName: "levelup" */ '../views/LevelUp.vue') },
  { path: '/trade', name: 'trade',  component: () => import(/* webpackChunkName: "trade" */ '../views/Trade.vue') },
  { path: '/orders', name: 'orders',  component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue') },
  { path: '/onboarding', name: 'onboarding',  component: () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding.vue') },
  { path: '/contact', name: 'contact',  component: () => import(/* webpackChunkName: "contact" */ '../views/ContactUs.vue') },
  { path: '/faq', name: 'faq',  component: () => import(/* webpackChunkName: "fAQ" */ '../views/FAQ.vue') },
  { path: '/api', name: 'APIDocs',  component: () => import(/* webpackChunkName: "apidocs" */ '../views/APIDocs.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
